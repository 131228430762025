import { template as template_7b23d1fb71db4002b9e2c7907d0196ca } from "@ember/template-compiler";
const FKLabel = template_7b23d1fb71db4002b9e2c7907d0196ca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
